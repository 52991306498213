import React from 'react'
import Collapsed, { Title, TitleWrapper } from '../../molecules/Collapsed'
import { FColG16, FRow } from '../../atoms/Layout'
import InfoSection, { colors, InfoSectionProps } from '../../atoms/InfoSection'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import {
    ImagePreviewWrapper,
    ImgPreviewFloating,
    Squareweeb,
    TitlesWrapper,
} from './styled'

export type CharacteristicInfo = {
    header: string
    content: string
} & Pick<InfoSectionProps, 'color' | 'icon'>

export type OurCharactersticsProps = {
    info: CharacteristicInfo[]
}

export const OurCharacterstics: React.FC<OurCharactersticsProps> = React.memo(
    ({ info }) => {
        const { onLaptop } = useRenderBreakpoints()
        const [focused, setFocused] = React.useState(-1)

        return onLaptop(
            ({ matches: laptop }) => {
                const inferredIndex = !laptop && focused === -1 ? 0 : focused
                const targetInfo = info[inferredIndex]

                if (!laptop) {
                    return (
                        <React.Fragment>
                            <FRow>
                                <TitlesWrapper>
                                    {info.map((characteristic, index) => {
                                        const handleFocus = () => {
                                            setFocused(index)
                                        }
                                        return (
                                            <TitleWrapper
                                                key={index}
                                                style={
                                                    inferredIndex === index
                                                        ? {
                                                              opacity: 1,
                                                          }
                                                        : undefined
                                                }
                                                onMouseEnter={handleFocus}
                                                onClick={handleFocus}
                                            >
                                                <Title>{characteristic.header}</Title>
                                            </TitleWrapper>
                                        )
                                    })}
                                </TitlesWrapper>
                                <ImagePreviewWrapper>
                                    <ImgPreviewFloating>
                                        {targetInfo && (
                                            <React.Fragment>
                                                <InfoSection
                                                    color={targetInfo.color}
                                                    icon={targetInfo.icon}
                                                >
                                                    {targetInfo.content}
                                                </InfoSection>
                                                <Squareweeb
                                                    style={{
                                                        color: colors[targetInfo.color],
                                                    }}
                                                />
                                            </React.Fragment>
                                        )}
                                    </ImgPreviewFloating>
                                </ImagePreviewWrapper>
                            </FRow>
                        </React.Fragment>
                    )
                }

                return (
                    <FColG16>
                        {info.map((characteristic, index) => {
                            return (
                                <Collapsed
                                    key={index}
                                    title={characteristic.header}
                                    open={inferredIndex === index}
                                    onClick={() => {
                                        setFocused((p) => (p === index ? -1 : index))
                                    }}
                                >
                                    <InfoSection
                                        color={characteristic.color}
                                        icon={characteristic.icon}
                                    >
                                        {characteristic.content}
                                    </InfoSection>
                                </Collapsed>
                            )
                        })}
                    </FColG16>
                )
            },
            {
                mode: 'handle-both',
            },
        )
    },
)

OurCharacterstics.displayName = 'OurCharacterstics'

export default OurCharacterstics
