import React from 'react'
import { FColG16 } from '../Layout'
import Stars from '../Stars'
import { BottomRow, Data, Quote, ReadMore, Title, Wrapper } from './styled'

export type TestimonyProps = {
    title: string
    quote: string
    who: string
    where: string
    onReadMore?(): void
    rate: number
}

export const Testimony: React.FC<TestimonyProps> = React.memo(
    ({ title, quote, who, where, onReadMore, rate }) => {
        return (
            <Wrapper>
                <FColG16>
                    <Stars rate={rate} />
                    <div>
                        <Title>{title}</Title>
                    </div>
                </FColG16>
                <Quote>“{quote}”</Quote>
                <BottomRow>
                    <Data>
                        <div>{who}</div>
                        <div>{where}</div>
                    </Data>
                    <ReadMore onClick={onReadMore}>Leer más</ReadMore>
                </BottomRow>
            </Wrapper>
        )
    },
)

Testimony.displayName = 'Testimony'

export default Testimony
