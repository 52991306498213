import { Bg } from '../../atoms/Bg'
import styled from 'styled-components'
import { FRowG16 } from '../../atoms/Layout'

export const SBg = styled(Bg)`
    align-items: center;
`

export const CarouselWrapper = styled(FRowG16)`
    margin-top: 80px;
    margin-bottom: 64px;
`
