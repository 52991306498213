import React from 'react'
import RenewIsHard from '../RenewIsHard'
import CollectionCarousel from '../CollectionCarousel'

export type CountWithUsProps = {}

export const CountWithUs: React.FC<CountWithUsProps> = React.memo(({}) => {
    return (
        <React.Fragment>
            <RenewIsHard />
            <CollectionCarousel />
        </React.Fragment>
    )
})

CountWithUs.displayName = 'CountWithUs'

export default CountWithUs
