import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EstimateButton from '../EstimateButton'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import { EstimateButtonWrapper, IntroductionImg, TitleSpan } from './styled'
import IntroAnimatedImage from '../../molecules/IntroAnimatedImage'

export type IntroductionProps = {}

export const Introduction: React.FC<IntroductionProps> = React.memo(({}) => {
    const { t } = useTranslation(['index', 'common'])
    const { onTablet } = useRenderBreakpoints()

    return (
        <IntroAnimatedImage
            title={
                <React.Fragment>
                    <TitleSpan>{t('constructions.title.part1')}</TitleSpan>
                    <br />
                    {t('constructions.title.part2')}
                </React.Fragment>
            }
            description={t('constructions.description')}
            img={<IntroductionImg />}
            afterDescription={
                <EstimateButtonWrapper>
                    {onTablet(
                        ({ matches }) => (
                            <EstimateButton size={matches ? 'normal' : undefined} />
                        ),
                        { mode: 'handle-both' },
                    )}
                </EstimateButtonWrapper>
            }
            bgColor={'blue'}
        />
    )
})

Introduction.displayName = 'Introduction'

export default Introduction
