import styled from 'styled-components'
import { FCol } from '../../atoms/Layout'
import SquareweebSvg from '../../images/squareweeb.svg'

export const TitlesWrapper = styled(FCol)`
    flex-basis: 50%;
    padding-bottom: 128px;
`

export const ImagePreviewWrapper = styled(FCol)`
    flex-basis: 50%;
    position: relative;
`

export const ImgPreviewFloating = styled(FCol)`
    position: absolute;
    left: 64px;
    right: 30%;
    bottom: 0;
    z-index: 1;
`

export const Squareweeb = styled(SquareweebSvg)`
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
`
