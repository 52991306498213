import styled from 'styled-components'
import { Bg } from '../../atoms/Bg'
import { sBreakpoints } from '../../constants'
import { FCol } from '../../atoms/Layout'
import { H3 } from '../../atoms/Text'
import Content from '../../atoms/Content'

export const SBg = styled(Bg)`
    margin-top: 128px;
    align-items: center;

    ${sBreakpoints.tablet} {
        margin-top: 16px;
    }
`
export const TitleWrapper = styled(FCol)`
    justify-content: center;
    align-items: center;
    padding-top: 128px;
    padding-bottom: 128px;

    ${sBreakpoints.tablet} {
        padding-top: 32px;
        padding-bottom: 32px;
    }
`

export const Title = styled(H3)`
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 0;

    ${sBreakpoints.tablet} {
        font-size: 1.4rem;
        margin-bottom: 48px;
    }
`

export const SContent = styled(Content)`
    padding-left: 32px;
    padding-right: 32px;

    ${sBreakpoints.tablet} {
        padding-left: 0;
        padding-right: 0;
    }
`
