import React from 'react'
import { BubbleMessage, Expand, MessageWrapper, Root, UserPhoto } from './styled'

export type UserMessagesProps = {
    photo: string
    messages: React.ReactNode[]
    direction: 'left' | 'right'
    color: 'purple' | 'orange'
}

export const UserMessages: React.FC<UserMessagesProps> = React.memo(
    ({ photo, messages, direction, color }) => {
        const content = [
            <UserPhoto key={'photo'} url={photo} />,
            <MessageWrapper key={'messages'} direction={direction}>
                {messages.map((message, k) => (
                    <BubbleMessage key={k} direction={direction} color={color}>
                        {message}
                    </BubbleMessage>
                ))}
            </MessageWrapper>,
            <Expand key={'expand'} />,
        ]
        if (direction === 'right') content.reverse()
        return <Root direction={direction}>{content}</Root>
    },
)

UserMessages.displayName = 'UserMessages'

export default UserMessages
