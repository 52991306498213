import React from 'react'
import UserMessages from '../UserMessages'
import guyBubble from '../../images/guybubble.jpg'
import girlBubble from '../../images/girlbubble.jpg'
import LoadingDots from '../../atoms/LoadingDots'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { range } from '../../utils'
import SectionTitle from '../../molecules/SectionTitle'
import { SBg, SContent, SpaceV128, SpaceV16 } from './styled'

export type ChatPreviewProps = {}

export const ChatPreview: React.FC<ChatPreviewProps> = React.memo(({}) => {
    const { t } = useTranslation('index')
    return (
        <SBg color={'white'} useHPadding={true} style={{ alignItems: 'center' }}>
            <SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                <SectionTitle
                    title={['Tu compañero de', 'renovacion']}
                    description={
                        'Olvídate de los sobrecostos, a los plazos incumplidos y los contratistas poco fiables. En Conamer, obtienes un gerente de proyecto dedicado al que puedes llamar o enviar un mensaje de texto con cualquier pregunta de principio a fin.'
                    }
                />
                <SpaceV128 />
                <UserMessages
                    photo={guyBubble}
                    color={'purple'}
                    direction={'left'}
                    messages={range(2).map((i) =>
                        t(`chatPreview.client.messages.${i + 1}`),
                    )}
                />
                <SpaceV16 />
                <UserMessages
                    color={'orange'}
                    photo={girlBubble}
                    direction={'right'}
                    messages={[
                        t('chatPreview.admin.messages.1'),
                        <LoadingDots key={'loading'} />,
                    ]}
                />
                <SpaceV128 />
            </SContent>
        </SBg>
    )
})

ChatPreview.displayName = 'ChatPreview'

export default ChatPreview
