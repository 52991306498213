import React from 'react'
import ComparisonTable from '../../molecules/ComparisonTable'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import SquareWeebCut from '../../images/squareweebcut.svg'
import { Checkmark } from '../../atoms/Icons/Checkmark'
import {
    FeatureValueWrapper,
    Relative,
    SBg,
    SContent,
    SquareWeebCutWrapper,
    Title,
} from './styled'
import { useRenderBreakpoints } from '../../utils/hooks/render'

export type WhyUsProps = {}

export const WhyUs: React.FC<WhyUsProps> = React.memo(({}) => {
    const { t } = useTranslation('index')
    const features = ['experience', 'design', 'materials', 'support', 'construction']
    const { onTablet } = useRenderBreakpoints()

    return (
        <SBg color={'blue'} useHPadding={true}>
            <SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                <Title>{t('whyUs.title')}</Title>
                <Relative>
                    {onTablet(
                        () => (
                            <SquareWeebCutWrapper>
                                <SquareWeebCut />
                            </SquareWeebCutWrapper>
                        ),
                        { mode: 'hide' },
                    )}
                    <ComparisonTable
                        features={features.map((f) => t(`whyUs.table.features.${f}`))}
                        values={[
                            {
                                name: (
                                    <div>
                                        {t(`whyUs.table.withOthers.title.part1`)}
                                        <br />
                                        {t(`whyUs.table.withOthers.title.part2`)}
                                    </div>
                                ),
                                features: features.map((f) =>
                                    t(`whyUs.table.withOthers.features.${f}`),
                                ),
                            },
                            {
                                name: <div>{t(`whyUs.table.withUs.title`)}</div>,
                                features: features.map((f, k) => {
                                    return (
                                        <FeatureValueWrapper key={k}>
                                            <Checkmark />
                                            <div>
                                                {t(`whyUs.table.withUs.features.${f}`)}
                                            </div>
                                        </FeatureValueWrapper>
                                    )
                                }),
                            },
                        ]}
                    />
                </Relative>
            </SContent>
        </SBg>
    )
})

WhyUs.displayName = 'WhyUs'

export default WhyUs
