import * as React from 'react'
import { graphql } from 'gatsby'
import Header from '../molecules/Header'
import WhatWeDo from '../organisms/WhatWeDo'
import Introduction from '../organisms/Introduction'
import HowItWorks from '../organisms/HowItWorks'
import ChatPreview from '../organisms/ChatPreview'
import WhyUs from '../organisms/WhyUs'
import Testimonies from '../organisms/Testimonies'
import CountWithUs from '../organisms/CountWithUs'
import Footer from '../organisms/Footer'

// markup
const IndexPage: React.FC = () => {
    return (
        <main>
            <Header />
            <Introduction />
            <WhatWeDo />
            <HowItWorks />
            <ChatPreview />
            <WhyUs />
            <Testimonies />
            <CountWithUs />
            <Footer />
        </main>
    )
}

export default IndexPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
