import React from 'react'
import { Dashed } from '../../atoms/Separator'
import OurCharacterstics from '../OurCharacterstics'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { range } from '../../utils'
import { SBg, SContent, Title, TitleWrapper } from './styled'
import { useRenderBreakpoints } from '../../utils/hooks/render'

export type WhatWeDoProps = {}

export const WhatWeDo: React.FC<WhatWeDoProps> = React.memo(({}) => {
    const { t } = useTranslation(['index', 'common'])
    const { onTablet } = useRenderBreakpoints()

    const weHaveReimaginedText = () => {
        const parts = range(2).map((i) => t('weHaveReimagined.part' + (i + 1)))
        return parts.map((x, i) => {
            return (
                <React.Fragment key={i}>
                    {!!i && <br />}
                    {x}
                </React.Fragment>
            )
        })
    }

    return (
        <SBg color={'white'} useHPadding={true}>
            <SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                {onTablet(
                    () => (
                        <Dashed />
                    ),
                    { mode: 'hide' },
                )}
                <TitleWrapper>
                    <Title>{weHaveReimaginedText()}</Title>
                </TitleWrapper>

                <OurCharacterstics
                    info={[
                        {
                            header: t('weHandleEverythingForYou.header'),
                            icon: 'umbrella',
                            color: 'blue',
                            content: t('weHandleEverythingForYou.content'),
                        },
                        {
                            header: t('stunningDesigns.header'),
                            icon: 'palette',
                            color: 'orange',
                            content: t('stunningDesigns.content'),
                        },
                        {
                            header: t('qualityMaterials.header'),
                            icon: 'bathroom',
                            color: 'blue',
                            content: t('qualityMaterials.content'),
                        },
                        {
                            header: t('premiumConstruction.header'),
                            icon: 'helmet',
                            color: 'green',
                            content: t('premiumConstruction.content'),
                        },
                    ]}
                />
            </SContent>
        </SBg>
    )
})

WhatWeDo.displayName = 'WhatWeDo'

export default WhatWeDo
