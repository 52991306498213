import styled from 'styled-components'
import { FColG16, FColG8, FRowG16 } from '../Layout'
import { H3 } from '../Text'

export const Wrapper = styled(FColG16)`
    padding: 35px;
    background-color: white;
    justify-content: space-between;
    height: 466px;
    width: 466px;
    min-width: 466px;
    max-width: 466px;
    overflow: hidden;
    white-space: initial;
`
export const Title = styled(H3)`
    font-weight: bold;
    font-size: 2rem;
    line-height: 24px;
    color: #000000;
`
export const ReadMore = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: #b1b1b0;
    cursor: pointer;
`
export const Data = styled(FColG8)`
    font-size: 18px;
    line-height: 24px;
    color: #000000;
`
export const Quote = styled.blockquote`
    display: -webkit-box;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 16px 0;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`

export const BottomRow = styled(FRowG16)`
    justify-content: space-between;
    align-items: flex-end;
`
