import React from 'react'
import { makeStyles } from '@material-ui/core'
import { range } from '../../utils'
export type LoadingDotsProps = {
    nDots?: number
    r?: number
    space?: number
}

const useStyles = makeStyles({
    loader: {
        color: 'grey',
    },
    dot: {
        animation: '1s $blink infinite',
        fill: 'grey',
        '&:nth-child(2)': {
            animationDelay: '250ms',
        },
        '&:nth-child(3)': {
            animationDelay: '500ms',
        },
    },
    '@keyframes blink': { '50%': { fill: 'transparent' } },
})

export const LoadingDots: React.FC<LoadingDotsProps> = React.memo(
    ({ nDots = 3, r = 3, space = 4 }) => {
        const { loader, dot } = useStyles()
        return (
            <svg
                height={r * 2}
                width={nDots * 2 * r + space * (nDots - 1)}
                className={loader}
            >
                {range(nDots).map((index) => (
                    <circle
                        key={index}
                        className={dot}
                        cx={2 * r * index + index * space + r}
                        cy={r}
                        r={r}
                    />
                ))}
            </svg>
        )
    },
)

LoadingDots.displayName = 'LoadingDots'

export default LoadingDots
