import React from 'react'
import Content from '../../atoms/Content'
import { Bg } from '../../atoms/Bg'
import Testimony from '../../atoms/Testimony'
import SectionTitle from '../../molecules/SectionTitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AutoCarousel from '../../atoms/AutoCarousel'
import * as S from './styled'

export type TestimoniesProps = {}

export const Testimonies: React.FC<TestimoniesProps> = React.memo(({}) => {
    const { t } = useTranslation(['index', 'common'])
    return (
        <React.Fragment>
            <S.SBg color={'flesh'} useHPadding={true}>
                <Content variant={'transparent'} expanded={true}>
                    <SectionTitle
                        title={t('common:reviews')}
                        description={t('reviews.description')}
                    />
                </Content>
            </S.SBg>
            <Bg color={'flesh'} style={{ overflow: 'hidden' }}>
                <S.CarouselWrapper>
                    <AutoCarousel
                        count={10}
                        render={(i) => {
                            return (
                                <Testimony
                                    key={i}
                                    title={'Volvería a trabajar con ellos sin dudarlo'}
                                    quote={
                                        'Contraté a Conamer para una renovación que hice en diciembre para mi cuarto principal. Excelente trabajo.'
                                    }
                                    who={'Alyssa H.'}
                                    where={'Miami'}
                                    rate={5}
                                />
                            )
                        }}
                    />
                </S.CarouselWrapper>
            </Bg>
        </React.Fragment>
    )
})

Testimonies.displayName = 'Testimonies'

export default Testimonies
